import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

document.addEventListener('turbo:load', function () {
  // Activate Bootstrap popovers
  const popovers = $('[data-toggle="popover"]');
  if (popovers.length) {
    for (const popover of popovers) {
      $(popover).popover({
        container: 'body',
        trigger: $(popover).data('trigger') || 'hover',
      });
    }
  }

  // Activate Bootstrap tooltips
  const tooltips = $('[data-toggle="tooltip"]');
  if (tooltips.length) {
    tooltips.tooltip();
  }

  // Activate Bootstrap collapse items
  const collapses = $('.collapse');
  if (collapses.length) {
    collapses.collapse({
      toggle: false,
    });
  }

  // Custom file upload
  $(document).on('change', '.custom-file-input', function () {
    const fileName = $(this).val().split('\\').pop();
    $(this).siblings('.custom-file-label').addClass('selected').html(fileName);
  });
});

document.addEventListener('turbo:before-cache', function () {
  // Dispose of Bootstrap popovers
  const popovers = $('[data-toggle="popover"]');
  if (popovers.length) {
    popovers.popover('dispose');
  }

  // Dispose of Bootstrap tooltips
  const tooltips = $('[data-toggle="tooltip"]');
  if (tooltips.length) {
    tooltips.tooltip('dispose');
  }

  // Dispose of Bootstrap tabs
  const tabs = $('[data-toggle="tab"]');
  if (tabs.length) {
    tabs.tab('dispose');
  }

  // Dispose of Bootstrap collapse items
  const collapses = $('.collapse');
  if (collapses.length) {
    collapses.collapse('dispose');
  }
});
